import axios from 'axios';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';

import {MultiGrid, RequesterViewType} from 'components/MultiGrid';
import PageMetaData from 'components/PageMetaData';
import * as UserStore from 'stores/User';
import * as DashboardStore from 'stores/Dashboard';
import {CardActionType} from 'components/MultiGrid';
import {getLoanerString, getCookie} from '../utility';
@withRouter
@connect(
    (state) => ({user: state.user, dashboard: state.dashboard, bloomreach: state.bloomReach}),
    Object.assign({}, UserStore.actionCreators, DashboardStore.actionCreators)
)
export default class Quotes extends React.Component {
  static propTypes = {
      user: UserStore.StateShape,
      ...UserStore.ActionShape,
      ...ReactRouterPropTypes,
  };

  constructor(props) {
      super(props);
      const gridName = 'quotesColumns'
      const cachedViewType = getCookie(`requesterViewType-${gridName}`)

      const {
          dashboard: {myAccountFilter},
      } = props;
      this.state = {
          loading: false,
          gridName: 'quotesColumns',
          columns: [],
          view: 'Grid',
          viewType: cachedViewType ? RequesterViewType[cachedViewType] : myAccountFilter.lineItemIds.length > 0 ? RequesterViewType.AllRequesters : RequesterViewType.CurrentUser,

      };
      this.handleSelectQuote = this.handleSelectQuote.bind(this);
  }

  componentDidMount() {
      const {viewType, gridName} = this.state;
      const {history, bloomreach: {isPreview}} = this.props;

      if (isPreview) {
          history.push('/quotes/0');
          return;
      }

      axios
          .get(`/ShoppingService/api/v1/account/gridSetting/${gridName}`)
          .then((x) => {
              this.setState({columns: x.data.columns, view: x.data.view});
          });
      this.loadQuotes(viewType === RequesterViewType.AllRequesters);
  }
    
  loadQuotes(showAllQuotes) {
      const {
          loadQuotes,
          dashboard: {myAccountFilter},
          resetMyAccountFilter,
      } = this.props;
      this.setState({loading: true});
      loadQuotes(myAccountFilter.lineItemIds, showAllQuotes).then(() => {
          this.setState({loading: false});
          if (myAccountFilter.lineItemIds) resetMyAccountFilter();
      });
  }
  reLoadQuotes(showAllQuotes) {
      const {
          dashboard: {myAccountFilter},
          resetMyAccountFilter,
      } = this.props;
      let request = {
          lineItemIds: myAccountFilter.lineItemIds,
          ShowAllOrders: showAllQuotes,
      };
      return axios
          .post('/ShoppingService/api/v1/quotes/myOrders', request)
          .then((x) => {
              this.setState({records: x.data.records, loading: false});
              if (myAccountFilter.lineItemIds) resetMyAccountFilter();
          });
  }
  handleSelectQuote(quote) {
      const {history} = this.props;
      const {location} = window;
    
      history.push(`${quote.isServiceItem ? '/service/' : '/quotes/'}` + quote.lineItemId, {from: location.pathname + location.search});
  }
  onGridRefresh() {
      const {viewType} = this.state;
      this.reLoadQuotes(viewType === RequesterViewType.AllRequesters);
  }

  handleRequesterToggle(requesterViewType) {
      this.setState({viewType: requesterViewType});
      this.reLoadQuotes(requesterViewType === RequesterViewType.AllRequesters);
  }

  filterLoaners(data) {
      let filteredData = [];

      let quoteDescription = '';

      for (let i = 0; i < data.length; i++) {
          quoteDescription = data[i].description;
          if (quoteDescription && !quoteDescription.includes(getLoanerString())) {
              filteredData.push(data[i]);
          } 
      }
      return filteredData;
  }

  render() {
      const {gridName, columns, view, loading, viewType, records} = this.state;
      const {
          user: {quotes},
      } = this.props;
    
      let data = records ? records : quotes;

      if (data === quotes) {
          data = this.filterLoaners(data);
      } 

      const gridKeys = [
          {
              id: 'awaitingQuote',
              fieldName: 'groupNameAlt',
              value: 'Requested',
              tabDisplay: 'Awaiting Quote',
              height: '580',
          },
          {
              id: 'quoted',
              fieldName: 'groupNameAlt',
              value: 'eQuoted',
              tabDisplay: 'Quoted',
              cardAction: CardActionType.Quote,
              height: '580',
          },

      ];

      return (
          <div>
              <PageMetaData title="Quotes" pageType="other" trackAnalytics={true} />
              <MultiGrid
                  onRequesterToggle={::this.handleRequesterToggle}
                  showRequesterToggle={true}
                  requesterViewType={viewType}
                  gridName={gridName}
                  view={view}
                  gridKeys={gridKeys}
                  data={data}
                  columns={columns}
                  label="My Account"
                  subLabel="Parts Quotes"
                  onSelect={this.handleSelectQuote}
                  loadingData={loading}
                  onRefresh={::this.onGridRefresh}
              />
          </div>
      );
  }
}
