import axios from 'axios';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';

import {MultiGrid, RequesterViewType, CardActionType} from 'components/MultiGrid';
import PageMetaData from 'components/PageMetaData';
import * as UserStore from 'stores/User';
import * as DashboardStore from 'stores/Dashboard';
import _ from 'lodash';
import {getCookie} from 'utility';
@withRouter
@connect(
    (state) => ({user: state.user, dashboard: state.dashboard}),
    Object.assign({}, UserStore.actionCreators, DashboardStore.actionCreators)
)
export default class OnSiteService extends React.Component {
  static propTypes = {
      user: UserStore.StateShape,
      ...UserStore.ActionShape,
      ...ReactRouterPropTypes,
  };

  constructor(props) {
      super(props);
      const gridName = 'onSiteServiceColumns'
      const cachedViewType = getCookie(`requesterViewType-${gridName}`)
      const {
          dashboard: {myAccountFilter},
      } = props;
      this.state = {
          loading: false,
          gridName,
          columns: [],
          view: 'Grid',
          viewType: cachedViewType ? RequesterViewType[cachedViewType] : myAccountFilter.lineItemIds.length > 0 ? RequesterViewType.AllRequesters : RequesterViewType.CurrentUser,
      };
      this.handleSelectQuote = this.handleSelectQuote.bind(this);
  }

  componentDidMount() {
      const {viewType, gridName} = this.state;
      axios
          .get(`/ShoppingService/api/v1/account/gridSetting/${gridName}`)
          .then((x) => {
              this.setState({columns: x.data.columns, view: x.data.view});
          });

      this.loadQuotes(viewType === RequesterViewType.AllRequesters);
  }

  loadQuotes(showAllQuotes) {
      const {
          loadServiceOrders,
          dashboard: {myAccountFilter},
          resetMyAccountFilter,
      } = this.props;
      this.setState({loading: true});
      loadServiceOrders(myAccountFilter.lineItemIds, showAllQuotes).then(() => {
          this.setState({loading: false});
          if (myAccountFilter.lineItemIds) resetMyAccountFilter();
      });
  }
  handleSelectQuote(quote) {
      const {history} = this.props;
      const {location} = window;
      history.push('/service/' + quote.lineItemId, {from: location.pathname + location.search});
  }
  onGridRefresh() {
      const {viewType} = this.state;
      this.loadQuotes(viewType === RequesterViewType.AllRequesters);
  }

  handleRequesterToggle(requesterViewType) {
      this.setState({viewType: requesterViewType});
      this.loadQuotes(requesterViewType === RequesterViewType.AllRequesters);
  }
  render() {
      const {gridName, columns, view, loading, viewType, records} = this.state;
      const {
          user: {serviceOrders, settings: {awaitingPoEnabled,showApprovalTab}},
      } = this.props;
    
      let data = serviceOrders;
    
      const gridKeys = [
          {
              id: 'initiated',
              fieldName: 'status',
              value: 'Initiated',
              tabDisplay: 'Initiated',
          },
          {
              id: 'quoted',
              fieldName: 'status',
              value: 'Quoted',
              tabDisplay: 'Quoted',
              cardAction: CardActionType.Quote,
          },
          {
              id: 'awaitingApproval',
              fieldName: 'status',
              value: 'Awaiting Approval',
              tabDisplay: 'Awaiting Approval',
          },
          {
              id: 'awaitingPO',
              fieldName: 'status',
              value: 'Awaiting PO',
              tabDisplay: 'Awaiting PO',
          },
          {
              id: 'openServiceEvent',
              fieldName: 'status',
              value: 'Open Service',
              tabDisplay: 'Open Service Event',
          },
          {
              id: 'workCompleted',
              fieldName: 'status',
              value: 'Work Completed',
              tabDisplay: 'Work Completed',
          },
      
      ];
      if (!awaitingPoEnabled) _.remove(gridKeys, x => x.id === 'awaitingPO');
      if (!showApprovalTab) _.remove(gridKeys, x => x.id === 'awaitingApproval');
      return (
          <div>
              <PageMetaData
                  title="On-Site Service"
                  pageType="other"
                  trackAnalytics={true}
              />
              <MultiGrid
                  onRequesterToggle={::this.handleRequesterToggle}
                  showRequesterToggle={true}
                  requesterViewType={viewType}
                  gridName={gridName}
                  view={view}
                  gridKeys={gridKeys}
                  data={data}
                  columns={columns}
                  label="My Account"
                  subLabel="On-Site Service"
                  cardAction={'ServiceEvent'}
                  onSelect={this.handleSelectQuote}
                  loadingData={loading}
                  onRefresh={::this.onGridRefresh}
              />
          </div>
      );
  }
}
